<template>
  <div class="activity-timer">
    <div class="timer__time">
      <span>{{ data.hour }}</span>
      <span class="timer__separator">:</span>
      <span>{{ data.minute }}</span>
      <span class="timer__separator">:</span>
      <span>{{ data.second }} </span>
    </div>
    <div class="timer__label">
      <span>H</span>
      <span>M</span>
      <span>S</span>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    data: {
      type: Object,
      default: () => ({
        hour: null,
        minute: null,
        second: null,
      }),
    },
  },
};
</script>
<style lang="scss" scoped>
.timer__time {
  display: flex;
  span:not(.timer__separator) {
    width: 50px;
    text-align: center;
  }
}

.timer__time,
.timer__label {
  span:not(.timer__separator) {
    width: 50px !important;
    text-align: center;
  }
  width: 100%;
}
span {
  font-family: "Raleway";
  font-style: normal;
  font-weight: 400;
  color: #fff;
}
.timer__time > span {
  font-size: 36px;
  line-height: 50px;
}
.timer__label {
  display: flex;
  flex-direction: row;
  width: 100%;
  gap: 39px;
  justify-content: space-between;
  span {
    font-size: 14px;
    line-height: 20px;
  }
}

.timer__separator {
  margin: 0 16px;
  width: 7px !important;
}

@media (max-width: 480px) {
  .timer__time {
    display: flex;
    flex-direction: row;
    justify-content: center;
    span:first-of-type,
    span:last-of-type {
      width: 50px !important;
      text-align: center;
    }
    span {
      font-size: 22px;
      line-height: 30px;

      &:not(.timer__separator) {
        width: 31px;
      }
    }
    .timer__separator {
      margin: 0 16px;
      width: 5px !important;
    }
    margin-bottom: 10px;
    span:nth-of-type(3) {
      width: 31px !important;
    }
  }
  .timer__label {
    span {
      text-align: center;
      width: 42.33px;
      font-size: 12px;
      line-height: 16px;
    }
    font-size: 12px;
    line-height: 16px;
    width: 100% !important;
    display: flex;
    justify-content: center;
    gap: 29px;
    margin-left: 0;
  }
}
</style>
