<template>
    <button
        title=""
        @click="$emit('click')"
        :class="[customClass, {
            'quantity-button white-button': buttonType === 'quantity',
            'side-button white-button': buttonType === 'side',
            'save-button brown-button': buttonType === 'save',
        }]"
    >
        <slot />
    </button>
</template>

<script>
export default {
    props: {
        buttonType: {
            type: 'quantity' | 'side' | 'save',
            default: 'side'
        },
        customClass: {
            type: String,
            default: ''
        }
    }
}
</script>

<style lang="scss" scoped>
button {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    padding: 0;
}

.white-button {
    background: #fff;
    border: 1px solid #974900;
    color:#974900;

    &:hover {
        background: #FFEDE2;
    }

    &:active {
        background: #FFDBC4;
    }
}

.brown-button {
    background: #974900;
    border: none;
    color: #fff;

    &:hover {
      background: #9F5714 !important;
    }

    &:active {
      background: #A45F1F !important;
    }
}

.save-button {
    border-radius: 5px;
    padding: 20px 0;
    margin-top: 24px;
    font-weight: 600;
    font-size: 20px;
    line-height: 23px;
    letter-spacing: 0.0125em;
}

.side-button {
    height: 46px;
    min-width: 46px;
}

.quantity-button {
    width: 20%;
    height: 46px;
    font-weight: 600;
    font-size: 17px;
    line-height: 20px;
    letter-spacing: 0.0125em;
}

@media (max-width: 768px) and (min-width: 593px) {
    .quantity-button {
        height: 76px !important;
        font-size: 26px !important;
        line-height: 36px !important;
    }
}

@media (max-width: 593px) {
    .quantity-button {
        font-size: 12px !important;
        height: 28px !important;
        min-width: 28px !important;
    }

    .save-button {
        padding: 9px 0 !important;
        font-size: 14px !important;
        line-height: 20px !important;
    }

    .side-button {
        height: 28px !important;
        min-width: 28px !important;
    }
}
</style>
