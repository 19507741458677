<template>
    <button @click="$emit('click')">
        <span>
            <slot/>
        </span>
    </button>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
button {
    background: #974900;
    padding: 20px 35px;
    border-radius: 5px;
    width: 100%;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;


    &:hover {
        background: #9F5714;
    }

    &:active {
        background: #A45F1F;
    }

    span {
        font-weight: 600;
        font-size: 20px;
        line-height: 23px;
        color: #FFFFFF;
    }
}

@media (max-width: 425px) {
    button {
        padding: 8px 21px !important;
        span {
            font-size: 14px !important;
            line-height: 20px !important;
        }
    }
}
</style>
