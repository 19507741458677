<template>
    <ul id="production-progress__multiple-order-list">
        <MultipleOrderItem
            v-for="order, index in orders"
            :key="`order-${index}`"
            :order="order"
            :removeOrder="() => openRemoveAppointmentConfirmModal(order.id)"
        />
    </ul>
</template>

<script>
import MultipleOrderItem from './components/MultipleOrderItem';

export default {
    components: {
        MultipleOrderItem
    },
    props: {
        orders: {
            type: Array,
            default: []
        },
        removeOrder: {
            type: Function,
            default: () => {}
        }
    },
    data: () => ({
        startDragging: (e) => {},
        stopDragging: (e) => {},
        dragging: (e) => {},
    }),
    mounted() {
        $(() => {
            const slider = document.querySelector("#production-progress__multiple-order-list");
            let mouseDown = false;
            let startX;
            let scrollLeft;

            const startDragging = function (e) {
                slider.classList.add("dragging");
                mouseDown = true;
                startX = e.pageX - slider.offsetLeft;
                scrollLeft = slider.scrollLeft;
            };
            const stopDragging = function (e) {
                slider.classList.remove("dragging");
                mouseDown = false;
            };
            const dragging = function (e) {
                e.preventDefault();
                if (!mouseDown) {
                    return;
                }
                const x = e.pageX - slider.offsetLeft;
                const scroll = x - startX;
                slider.scrollLeft = scrollLeft - scroll;
            }

            this.startDragging = startDragging;
            this.stopDragging = stopDragging;
            this.dragging = dragging;

            slider?.addEventListener("mousemove", dragging);
            slider?.addEventListener("mousedown", startDragging, false);
            slider?.addEventListener("mouseup", stopDragging, false);
            slider?.addEventListener("mouseleave", stopDragging, false);
        });
    },
    beforeDestroy() {
        const slider = document.querySelector("#production-progress__multiple-order-list");
        slider?.removeEventListener("mousemove", this.dragging);
        slider?.removeEventListener("mousedown", this.startDragging);
        slider?.removeEventListener("mouseup", this.stopDragging);
        slider?.removeEventListener("mouseleave", this.stopDragging);
    },
    methods: {
        openRemoveAppointmentConfirmModal(appointmentId) {
            this.openModal(appointmentId);
        },
        removeAppointment(appointmentId) {
            this.$emit("removeAppointment", appointmentId);
        },
        openModal(appointmentId) {
            const modal = {
                title: this.$t(
                    "ProductionProgress.RemoveAppointmentConfirmationModal.Title"
                ),
                content: this.$t(
                    "ProductionProgress.RemoveAppointmentConfirmationModal.Content"
                ),
                cancelButton: this.$t(
                    "ProductionProgress.RemoveAppointmentConfirmationModal.Cancel"
                ),
                confirmButton: this.$t(
                    "ProductionProgress.RemoveAppointmentConfirmationModal.Confirm"
                ),
            };

            this.$swal({
                title: modal.title,
                text: modal.content,
                showCancelButton: true,
                showCloseButton: true,
                cancelButtonText: modal.cancelButton,
                confirmButtonText: modal.confirmButton,
                customClass: {
                    container: "swal-default",
                    confirmButton: "btn btn-primary",
                    cancelButton: "btn btn-outline-danger ml-1",
                },
                buttonsStyling: false,
            })
            .then(({ isConfirmed }) => isConfirmed && this.removeAppointment(appointmentId));
        },
    },
}
</script>

<style lang="scss" scoped>
#production-progress__multiple-order-list {
    /* list-style: none; */
    margin: 0;
    padding: 0;
    overflow-x: auto;
    overflow-y: hidden;
    display: flex;
    gap: 16px;
    cursor: grabbing;

    &::-webkit-scrollbar {
        display: none !important;
    }
}
</style>
