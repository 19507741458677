<template>
    <div class="setup-alert">
        <slot />
    </div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.setup-alert {
    background: #FFEBEE;
    border: 1px solid #B71C1C;
    border-radius: 6px;
    padding: 15px;
    margin-bottom: 24px;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #B71C1C;
    word-break: keep-all;
}
</style>
