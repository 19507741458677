<template>
    <div class="quantity-input-container">
        <div class="input-view">
            {{ quantity }}
        </div>

        <input
            ref="quantityInput"
            inputmode="numeric"
            :value="quantity"
            @blur="setInputFocus"
            @keydown="filterInput($event)"
            @input="formatInput($event)"
        />
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
export default {
    props: {
        newQuantity: {
            type: Number,
            default: 0
        },
        closeKeyboard: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        ...mapGetters('app', [
            'flagShowModalPin',
        ]),
    },
    data: () => ({ quantity: 0 }),
    mounted() {
        setTimeout(() => this.setInputFocus(), 1000);
    },
    watch: {
        newQuantity(value) {
            this.quantity += value;
            this.formatInput('');
        },
        closeKeyboard(value) {
            value && this.setInputBlur();
        },
        flagShowModalPin() {
            this.setInputFocus();
        }
    },
    methods: {
        setInputFocus() {
           !this.closeKeyboard && !this.flagShowModalPin && this.$refs.quantityInput?.focus();
        },
        setInputBlur() {
            this.$refs.quantityInput?.blur();
        },
        filterInput(event) {
            const input = ['0', '1', '2', '3', '4', '5', '6', '7', '8', '9'];
            const keyPressed = event.key;
            const keyCode = event.keyCode ?? event.which;

            if ((!input.includes(keyPressed) && (keyCode != 8))) event.preventDefault();
        },
        formatInput({ data }) {
            const string = this.quantity.toString();

            if(data === null && string.length === 0 && this.newQuantity === 0 ) return;
            if(data === null && this.quantity > 0 && this.newQuantity === 0) {
                this.quantity = string.length > 1 ? Number(string.slice(0, string.length - 1)) : 0;
                this.$emit('setQuantityValue', this.quantity);
                return;
            }
            const dataParam = data ?? '';
            const value = Number(string + dataParam);
            const replacedValue = value > Number.MAX_SAFE_INTEGER ? Number.MAX_SAFE_INTEGER : value;

            this.quantity = replacedValue;
            this.$emit('setQuantityValue', replacedValue);
        },
    },
}
</script>

<style lang="scss" scoped>
.quantity-input-container {
    width: 100%;

    .input-view {
        width: 100%;
        height: 50px;
        font-weight: 600;
        font-size: 36px;
        line-height: 50px;
        text-align: center;
        color: #4C4541;
        caret-color: transparent;
        user-select: none;
        cursor: pointer;
    }

    input {
        position: absolute;
        height: 0;
        width: 0;
        opacity: 0;
    }
}

@media (max-width: 768px) {
    .quantity-input-container {
        .input-view {
            display: none;
        }

        input{
            height: 50px;
            width: auto;
            opacity: 1;
            border: none !important;
            position: relative;
            width: 100%;
            text-align: center;
            font-weight: 600;
            font-size: 60px;
            line-height: 50px;
            color: #4C4541;
            caret-color: transparent;
            user-select: none;
            &:focus{
                outline: 0;
            }
        }
    }
}

@media (max-width: 768px) and (min-width: 593px) {
    .quantity-input-container {
        .input-view {
            font-size: 60px !important;
        }
    }
}

@media (max-width: 593px) {
    .quantity-input-container {
        input{
            height: 36px;
            font-size: 26px !important;
            line-height: 36px !important;
        }
    }
}
</style>
