<template>
    <header class="header-container">
        <h1>{{ $t(`${translationBase}${title}`) }}</h1>
        <button @click="close">
            <CloseIcon v-if="windowWidth > 425 && windowWidth < 768" />
            <CloseIconSmall v-else />
        </button>
    </header>
</template>

<script>
import CloseIcon from '@/assets/images/icons/close-icon-1.svg';
import CloseIconSmall from '@/assets/images/icons/close-icon.svg';

export default {
    components: {
        CloseIcon,
        CloseIconSmall
    },
    props: {
        title: {
            type: String,
            default: 'Status' | 'Resource' | 'Reason'
        },
        close: {
            type: Function,
            default: () => {}
        },
        translationBase: {
            type: String,
            default: '',
        }
    },
    data: () => ({
        windowWidth: window.innerWidth,
    })
}
</script>

<style lang="scss" scoped>
.header-container {
    display: flex;
    align-items: center;
    padding: 16px 29px;
    box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.14), 0px 3px 3px rgba(0, 0, 0, 0.12), 0px 1px 8px rgba(0, 0, 0, 0.2);
    user-select: none;
    z-index: 99999999999;

    h1 {
        flex-grow: 1;
        font-weight: 600;
        font-size: 22px;
        line-height: 30px;
        color: #4C4541;
        margin: 0;
    }

    button {
        padding: 0;
        border: none;
        background: none;
    }
}

@media (max-width: 768px) and (min-width: 425px) {
    .header-container {
        h1 {
            font-size: 36px !important;
            line-height: 50px !important;
        }
    }
}

@media (max-width: 425px) {
    .header-container {
        padding: 16px !important;

        h1 {
            font-size: 18px !important;
            line-height: 26px !important;
        }
    }
}
</style>
