<template>
    <li class="production-progress__multiple-order-item">
        <span>
            {{ order.name }}
        </span>

        <button @click="removeOrder">
            <TrashIcon />
        </button>
    </li>
</template>

<script>
import TrashIcon from "@/assets/images/new-icons/trash-icon.svg";

export default {
    components: {
        TrashIcon
    },
    props: {
        order: {
            type: Object,
            default: () => {}
        },
        removeOrder: {
            type: Function,
            default: () => {}
        }
    }
}
</script>

<style lang="scss" scoped>
.production-progress__multiple-order-item {
    border-right: 2px solid #cfc4be;
    list-style: none;
    display: flex;
    align-items: center;

    &:last-child {
        border: 0;
    }

    span {
        color: #4C4541;
        font-size: 26px;
        font-weight: 600;
        line-height: 36px;
    }

    button {
        background: transparent;
        border: none;
        height: 38px;
        width: 38px;
        border-radius: 5px;
        outline: none;
        margin: 0 16px 0 7px;

        svg {
            height: 14px;
            width: 12px;
        }

        &:hover {
            background-color: #ffcdd2;
        }

        &:active {
            background-color: #ef9a9a;
            color: #c62828;
        }

        &:not(:active) {
            transition: all 0.25s ease-in-out;
        }
    }
}

@media (max-width: 480px) {
    .production-progress__multiple-order-item {
        span {
            font-size: 18px;
            line-height: 26px;
        }

        button {
            height: 28px;
            width: 28px;
        }
    }
}
</style>
