<template>
    <li
        @click="itemId !== selectedId && $emit('select-item')"
        :class="[{ 'selected-item': itemId === selectedId }]"
    >
        <span>
            <slot />
        </span>
    </li>
</template>

<script>
    export default {
        props: {
            selectedId: {
                type: String | null,
                default: null
            },
            itemId: {
                type: String | null,
                default: null
            }
        }
    }
</script>

<style lang="scss" scoped>
li {
    height: 84px;

    display: flex;
    align-items: center;

    padding: 0 29px;
    border-bottom: 1px solid #CFC4BE;
    user-select: none;
    cursor: pointer;

    &:hover {
        background: #FFEDE2;
        border-bottom: 1px solid #974900;

        span {
            color: #974900;
        }
    }

    &:active {
        background: #FFDBC4;
    }

    &.selected-item {
        background: #974900;
        border-bottom: 1px solid #974900;

        span {
            color: #fff;
        }
    }

    span {
        font-size: 14px;
        line-height: 20px;
        color: #4C4541;

        word-break: break-all;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
}

@media (max-width: 768px) and (min-width: 425px) {
    li {
        height: 94px !important;
        span {
            font-size: 22px !important;
            line-height: 30px !important;
        }
    }
}

@media (max-width: 425px) {
    li {
        height: 80px !important;
        padding: 32px 16px !important;

        span {
            font-size: 12px !important;
            line-height: 16px !important;
        }
    }
}
</style>
