<template>
    <div class="input-modal-container animation-open-modal">
        <ModalHeader :title="title" :close="closeModal"/>

        <div class="input-person-container">
            <div class="input-person-wrapper">
                <div class="side-button-container-extern">
                    <Button
                        buttonType="side"
                        @click="addQuantity(-1)"
                    >
                        <MinusIcon v-if="windowWidth > 593"/>
                        <MinusIconSmall v-else />
                    </Button>
                </div>

                <div class="center-container">
                    <div class="wrapper-container">
                        <div class="quantity-input">
                            <Button
                                buttonType="side"
                                customClass="side-button-container"
                                @click="addQuantity(-1)"
                            >
                                <MinusIcon v-if="windowWidth > 593"/>
                                <MinusIconSmall v-else />
                            </Button>

                            <div class="quantity-content">
                                <QuantityInput
                                   :newQuantity="newQuantity"
                                   :closeKeyboard="closeKeyboard"
                                   @setQuantityValue="(quantity) => setQuantityValue(quantity)"
                                />

                                <div class="gray-row"/>
                            </div>

                            <Button
                                buttonType="side"
                                customClass="side-button-container"
                                @click="addQuantity(1)"
                            >
                                <PlusIcon v-if="windowWidth > 593"/>
                                <PlusIconSmall v-else />
                            </Button>
                        </div>

                        <div class="buttons-row-container">
                            <Button
                                buttonType="quantity"
                                v-for="quantity in quantities"
                                :key="`quantity-${quantity}`"
                                @click="addQuantity(quantity)"
                                @setQuantityValue="(quantity) => setQuantityValue(quantity)"
                            >
                                + {{ quantity }}
                            </Button>
                        </div>
                    </div>
                    <Button
                        buttonType="save"
                        @click="save"
                    >
                        {{ $t('IntegrationPage.Save') }}
                    </Button>
                </div>

                <div class="side-button-container-extern">
                    <Button
                        buttonType="side"
                        @click="addQuantity(1)"
                    >
                        <PlusIcon v-if="windowWidth > 593"/>
                        <PlusIconSmall v-else />
                    </Button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import MinusIcon from '@/assets/images/icons/minus-icon-1.svg';
import MinusIconSmall from '@/assets/images/icons/minus-icon-2.svg';
import PlusIcon from '@/assets/images/icons/plus-icon-1.svg';
import PlusIconSmall from '@/assets/images/icons/plus-icon-2.svg';
import Button from '@/views/pages/operator/components/InputModal/components/Button/Button.vue';
import QuantityInput from '@/views/pages/operator/components/InputModal/components/QuantityInput/QuantityInput.vue';
import ModalHeader from '@/views/pages/operator/components/InputModal/components/ModalHeader/ModalHeader.vue';
import { mapMutations } from "vuex";

export default {
    components: {
        MinusIcon,
        MinusIconSmall,
        PlusIcon,
        PlusIconSmall,
        Button,
        QuantityInput,
        ModalHeader
    },
    props: {
        data: {
            type: Object,
            default: () => ({
                quantityProp: Number,
                title: String
            })
        }
    },
    computed: {
        quantityProp() {
            return this.data.quantityProp;
        },
        title() {
            return this.data.title;
        }
    },
    data: () => ({
        quantities: [5, 10, 25, 50, 100],
        newQuantity: 0,
        quantity: 0,
        windowWidth: window.innerWidth,
        closeKeyboard: false,
    }),
    mounted() {
        this.UPDATE_FLAG_SHOW_OVERLAY(false);
        this.addOpenAnimation();
        this.setQuantityProp();
    },
    methods:{
        ...mapMutations('app', [
            'UPDATE_FLAG_SHOW_OVERLAY'
        ]),
        addQuantity(quantity) {
            if((this.quantity === 0 && quantity < 0)) return;

            this.newQuantity = quantity;
            this.$nextTick(() => this.newQuantity = 0);
        },
        addOpenAnimation() {
            const modal = document.querySelector('.input-modal-container');
            modal.classList.add('animation-open-modal');

            setTimeout(() => document.querySelector('.input-modal-container').classList.remove('animation-open-modal'), 1000);
        },
        closeModal() {
            const modal = document.querySelector('.input-modal-container');
            modal.classList.add('animation-close-modal');
            modal.classList.add('closed-modal');
            this.closeKeyboard = true;

            setTimeout(() => this.$emit('close', this.title) , 1000);
        },
        setQuantityValue(value) {
            this.quantity = value;
        },
        setQuantityProp() {
            this.quantityProp > 0 && this.addQuantity(this.quantityProp);
        },
        save() {
            this.$emit('setQuantityValue', {
                title: this.title,
                quantity: this.quantity
            });

            this.closeModal();
        }
    }
}
</script>

<style lang="scss" scoped>
.input-modal-container {
    width: 100vw;
    height: 100vh;
    background: #fff;
    z-index: 99999999;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;

    &.closed-modal {
        top: 100vh;
    }

    .input-person-container {
        height: calc(100vh - 62px);
        display: flex;
        align-items: center;
        padding: 40px 45px;
        justify-content: center;

        .input-person-wrapper {
            display: flex;
            user-select: none;
            width: 100%;
            max-width: 818px;

            .center-container {
                display: flex;
                flex-direction: column;
                flex-grow: 1;

                .wrapper-container {
                    padding: 0px 16px 16px;
                    display: flex;
                    flex-direction: column;

                    .quantity-input {
                        display: flex;
                        align-items: center;

                        .quantity-content {
                            width: 100%;
                            padding: 0 16px;

                            .gray-row {
                                height: 2px;
                                background: #CFC4BE;
                                display: block;
                                margin-top: 8px;
                            }
                        }
                        .side-button-container {
                            display: none;
                        }
                    }


                    .buttons-row-container {
                        margin-top: 24px;
                        gap: 0 16px;
                        display: flex;
                        justify-content: center;
                    }
                }
            }
        }
    }

    &.animation-open-modal {
        animation: openModal .5s normal;
        @keyframes openModal {
            0% {
                top: 100vh;
            }
            100% {
                top: 0;
            }
        }
    }

    &.animation-close-modal {
        animation: closeModal .5s normal;
        @keyframes closeModal {
            0% {
                top: 0;
            }
            100% {
                top: 100vh;
            }
        }
    }
}

@media (max-width: 768px) and (min-width: 593px) {
    .input-modal-container {
        .input-person-container {
            height: calc(100vh - 82px);
            align-items: flex-start;

            .side-button-container-extern {
                display: none !important;
            }

            .quantity-input {
                .side-button-container {
                    display: flex !important;
                }
            }
        }
    }
}

@media (max-width: 593px) {
    .input-modal-container {
        .input-person-container {
            height: calc(100vh - 58px);
            align-items: flex-start;
            padding: 24px 16px;

            .input-person-wrapper {
                .center-container {
                    .wrapper-container {
                        padding: 0 !important;

                        .quantity-input {
                            .quantity-content {
                                .gray-row {
                                    margin-top: 8px !important;
                                }
                            }

                            .side-button-container {
                                display: flex !important;
                            }
                        }
                    }
                    .buttons-row-container {
                        margin-top: 16px !important;
                    }
                }
            }

            .side-button-container-extern {
                display: none !important;
            }
        }

    }
}
</style>
