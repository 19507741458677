<template>
  <div class="activity-card__finished">
    <div class="icon">
      <FinishIcon />
    </div>
    <div class="labels">
      <h1>{{ title }}</h1>
      <span v-if="datetime">{{ datetime }}</span>
    </div>
  </div>
</template>

<script>
import FinishIcon from "@/assets/images/icons/finish-card-icon.svg";

export default {
  components: {
    FinishIcon,
  },
  props: {
    title: {
      type: String,
    },
    datetime: {
      type: String,
      default: null,
    },
  },
  computed: {},
};
</script>

<style lang="scss">
$finish: #66bb6a;

.activity-card__finished {
  width: 100%;
  height: 100%;
  border-radius: 26px;

  padding-top: 5%;
  background-color: $finish;

  display: flex;
  gap: 16px;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .icon {
    background-color: #fff;
    border-radius: 6px;
    width: min-content;
    padding: 8px;
  }
  path {
    fill: $finish;
  }

  .labels {
    display: flex;
    flex-direction: column;
    gap: 8px;
    h1,
    span {
      color: #fff;
      font-family: "Raleway";
      font-style: normal;
      font-weight: 600;
    }
    h1 {
      margin-bottom: 0;
      text-align: center;
      font-size: 28px;
      line-height: 38px;
    }
    span {
      font-size: 18px;
      line-height: 26px;
    }
  }
}

@media (max-width: 480px) {
  .activity-card__finished {
    justify-content: flex-start;
    padding-top: 77px;
    .icon {
      padding: 0;
      width: 45px;
      height: 45px;
      svg,
      path {
        transform: scale(0.5);
        margin-left: -8.8px;
        margin-top: -7px;
      }
    }
    .labels {
      gap: 0px;
      h1 {
        font-size: 18px;
        line-height: 26px;
      }
      span {
        font-size: 16px;
        line-height: 26px;
      }
    }
  }
}
</style>
