<template>
    <div class="select-modal-container animation-open-modal">
        <ModalHeader
            :translation-base="translationBase"
            :title="title"
            :close="closeModal"
        />

        <ul class="list-content">
            <ItemList
                v-for="item, index in list"
                :selectedId="selectedId"
                :itemId="item.id"
                :key="`item-${index}`"
                @select-item="emitSelectItem(title, item)"
            >
                {{ item.name }}
            </ItemList>
        </ul>
    </div>
</template>

<script>
import { mapMutations } from 'vuex';
import ModalHeader from './components/ModalHeader/ModalHeader.vue';
import ItemList from './components/ItemList/ItemList.vue';

export default {
    components: {
        ModalHeader,
        ItemList
    },
    props: {
        title: {
            type: String,
            default: 'Reason',
            required: true,
        },
        selectedId: {
            type: String | null,
            default: null,
        },
        list: {
            type: Array,
            default: [],
            required: true
        },
        translationBase: {
            type: String,
            default: '',
        }
    },
    mounted() {
        this.UPDATE_FLAG_SHOW_OVERLAY(false);
        this.addOpenAnimation();
    },
    methods: {
        ...mapMutations('app', [
            'UPDATE_FLAG_SHOW_OVERLAY'
        ]),
        emitSelectItem(title, item) {
            const data = {
                title: title,
                item: item
            };

            this.$emit('select-item', data);
            this.closeModal();
        },
        addOpenAnimation() {
            const modal = document.querySelector('.select-modal-container');
            modal.classList.add('animation-open-modal');

            setTimeout(() => document.querySelector('.select-modal-container').classList.remove('animation-open-modal'), 1000);
        },
        closeModal() {
            const modal = document.querySelector('.select-modal-container');
            modal.classList.add('animation-close-modal');
            modal.classList.add('closed-modal');

            setTimeout(() => this.$emit('close', this.title) , 1000);
        },
    }
}
</script>

<style>
body:has(.select-modal-container) {
    overflow: hidden;
}
</style>

<style lang="scss" scoped>
.select-modal-container {
    overflow: auto;
    height: 100vh;
    width: 100vw;
    background: #FCFCFC;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999999999;
    display: flex;
    flex-direction: column;

    &.closed-modal { top: 100vh;}

    &.animation-open-modal {
        animation: openModal 0.5s normal;
        @keyframes openModal {
            0% {
                top: 100vh;
            }
            100% {
                top: 0;
            }
        }
    }

    &.animation-close-modal {
        animation: closeModal 0.5s normal;
        @keyframes closeModal {
            0% {
                top: 0;
            }
            100% {
                top: 100vh;
            }
        }
    }

    .list-content {
        &::-webkit-scrollbar {
            width: 16px;               /* width of the entire scrollbar */
        }

        &::-webkit-scrollbar-track {
            background: #fff;
        }

        &::-webkit-scrollbar-thumb {
            background-color:  #CFC4BE;   /* color of the scroll thumb */
            border-radius: 20px;       /* roundness of the scroll thumb */
            border: 4px solid #fff;
            height: 70px;
        }

        overflow: auto;
        padding: 0;
        margin: 0;
    }
}
</style>
