<template>
    <div
        :class="[
            'field-card-container',
            {'disabled-card': disabled},
            {'card-alert': alert}
        ]"
        @click="action"
    >
        <div class="container-icon">
            <component :is="cardIcon" />
        </div>
        <div class="container-content">
            <span class="container-title">
                {{ $t(`ProductionProgress.AppointmentModal.Card.${ this.title }`) }}
            </span>
            <div class="container-info">
                <div
                    class="container-value"
                    v-if="!alert"
                >
                    <span>{{ value }}</span>
                </div>
                <div
                    class="alert-container"
                    v-else
                >
                    <span>{{ $t(`ProductionProgress.AppointmentModal.Card.Select`) }}</span>
                    <component :is="warnIcon"/>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import ResourceIcon2 from '@/assets/images/icons/resource-icon-1.svg';
import ResourceIcon from '@/assets/images/icons/resource-icon-1.svg';
import ResourceIconTablet from '@/assets/images/icons/resource-icon-2.svg';
import ResourceIconMobile from '@/assets/images/icons/resource-icon-3.svg';
import DateIcon from '@/assets/images/icons/date-icon-1.svg';
import DateIconTablet from '@/assets/images/icons/date-icon-2.svg';
import DateIconMobile from '@/assets/images/icons/date-icon-3.svg';
import ProducedIcon from '@/assets/images/icons/produced-icon-1.svg';
import ProducedIconTablet from '@/assets/images/icons/produced-icon-2.svg';
import ProducedIconMobile from '@/assets/images/icons/produced-icon-3.svg';
import ReworkIcon from '@/assets/images/icons/rework-icon-1.svg';
import ReworkIconTablet from '@/assets/images/icons/rework-icon-2.svg';
import ReworkIconMobile from '@/assets/images/icons/rework-icon-3.svg';
import RejectionIcon from '@/assets/images/icons/refuse-icon-1.svg';
import RejectionIconTablet from '@/assets/images/icons/refuse-icon-2.svg';
import RejectionIconMobile from '@/assets/images/icons/refuse-icon-3.svg';
import ReasonIcon from '@/assets/images/icons/reason-icon-1.svg';
import ReasonIconTablet from '@/assets/images/icons/reason-icon-2.svg';
import ReasonIconMobile from '@/assets/images/icons/reason-icon-3.svg';
import StatusIcon from '@/assets/images/icons/status-icon-1.svg';
import StatusIconTablet from '@/assets/images/icons/status-icon-2.svg';
import StatusIconMobile from '@/assets/images/icons/status-icon-3.svg';
import WarnIcon from '@/assets/images/icons/warn-icon-1.svg';
import WarnIconTablet from '@/assets/images/icons/warn-icon-2.svg';
import WarnIconMobile from '@/assets/images/icons/warn-icon-3.svg';

export default {
    components: {
        ResourceIcon2,
        ResourceIcon,
        ResourceIconTablet,
        ResourceIconMobile,
        DateIcon,
        DateIconTablet,
        DateIconMobile,
        ProducedIcon,
        ProducedIconTablet,
        ProducedIconMobile,
        ReworkIcon,
        ReworkIconTablet,
        ReworkIconMobile,
        RejectionIcon,
        RejectionIconTablet,
        RejectionIconMobile,
        ReasonIcon,
        ReasonIconTablet,
        ReasonIconMobile,
        StatusIcon,
        StatusIconTablet,
        StatusIconMobile,
        WarnIcon,
        WarnIconTablet,
        WarnIconMobile,
    },
    props: {
        data: {
            type: Object,
            required: true
        }
    },
    computed: {
        cardIcon() {
            return this.getIcon();
        },
        warnIcon() {
            return this.getWarnIcon();
        },
        title() {
            return this.data.title;
        },
        value() {
            return this.data.value;
        },
        disabled() {
            return this.data.disabled;
        },
        alert() {
            return this.data.alert;
        },
    },
    data: () => ({
        windowWidth: window.innerWidth,
    }),
    methods: {
        action() {
            !this.disabled && this.data.action(this.title);
        },
        getIcon() {
            const icons = {
                resource: 'ResourceIcon',
                date: 'DateIcon',
                produced: 'ProducedIcon',
                rework: 'ReworkIcon',
                rejection: 'RejectionIcon',
                reason: 'ReasonIcon',
                status: 'StatusIcon'
            }
            const icon = icons[this.title.toLowerCase()];

            if(this.windowWidth > 768) return icon;

            const deviceIcon = this.windowWidth < 425 ? icon + 'Mobile' : icon + 'Tablet';
            return deviceIcon;
        },
        getWarnIcon() {
            if(this.windowWidth > 768) return 'WarnIcon';

            return this.windowWidth < 425 ? 'WarnIconMobile' : 'WarnIconTablet';
        }
    }
}
</script>

<style lang="scss" scoped>
.field-card-container {
    width: 100%;
    height: 100%;

    border: 1px solid #CFC4BE;
    border-radius: 16px;
    background: #FAFAFA;

    display: flex;
    user-select: none;
    cursor: pointer;


    &:hover {
        background: #FFEDE2;
        border-color:  #974900;

        .container-icon {
            border-color:  #974900;

            svg {
                fill: #974900;
            }
        }
    }

    &:active {
        background: #FFDBC4;
    }

    &.disabled-card {
        background: #E0E0E0 !important;
        border-color: #7E7570 !important;
        cursor: inherit !important;

        .container-title {
            color: #7E7570 !important;
        }
        svg {
            fill: #998F8A !important;
        }

        .container-icon {
            border-color: #7E7570 !important;
        }

        .container-content {
            .container-info {
                .alert-container {
                    span {
                        color: #4C4541 !important;
                    }
                }
            }
        }
    }

    &.card-alert {
        border-color: #FFA726;

        &:hover {
            border-color: #974900;
            .container-icon {
                border-color: #974900;
            }

            .container-content {
                .container-info {
                    .alert-container {
                        span {
                            color: #974900;
                        }

                        svg {
                            fill: #974900;
                        }
                    }
                }
            }
        }

        .container-icon {
            border-color: #FFA726;
        }
    }

    .container-icon {
        max-width: 68px;
        min-width: 68px;

        border-right: 1px solid #CFC4BE;

        display: flex;
        align-items: center;
        justify-content: center;

        svg {
            fill: #998F8A;
        }
    }

    .container-content {
        width: 100%;
        padding: 24px 24px 24px 16px;

        display: flex;
        flex-direction: column;
        justify-content: center;

        .container-title {
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #998F8A;
            padding-bottom: 4px;
        }
        .container-info {
            span {
                font-weight: 600;
                font-size: 16px;
                line-height: 26px;

                word-break: break-all;
                white-space: pre-wrap;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
            }

            .container-value {
                span {
                    color: #4C4541;
                }
            }


            .alert-container {
                display: flex;
                justify-content: space-between;

                span {
                    color: #FFA726;
                }

                svg {
                    fill: #FFA726;
                }
            }
        }
    }
}

@media (max-width: 768px) and (min-width: 425px) {
    .field-card-container {
        .container-icon {
            max-width: 72px !important;
            min-width: 72px !important;
        }

        .container-content {
            .container-title {
                font-size: 18px !important;
                line-height: 26px !important;
            }
            .container-info {
                span {
                    font-size: 22px !important;
                    line-height: 30px !important;
                }
            }
        }
    }
}

@media (max-width: 425px) {
    .field-card-container {
        .container-icon {
            min-width: 48px !important;
            max-width: 48px !important;
        }

        .container-content {
            width: 100%;
            padding: 16px 24px 16px 16px;

            display: flex;
            flex-direction: column;
            justify-content: center;

            .container-title {
                font-size: 12px !important;
                line-height: 16px !important;
            }

            .container-info {
                span {
                    font-size: 14px !important;
                    line-height: 20px !important;
                }
            }
        }
    }
}
</style>
